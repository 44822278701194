import React from "react";
import { graphql } from "gatsby";

import { Page } from "engine/pageContext";
import Layout from "../components/layout";
import Contacts from "../components/contacts";

export default function Template({
  data: { markdownRemark, localize },
  pageContext: { lang },
}) {
  const { html } = markdownRemark;

  return (
    <Page pageContext={{ lang, l: localize }}>
      <Layout title={markdownRemark.frontmatter.title}>
        <div className={"container pt-5"}>
          <div className="body" dangerouslySetInnerHTML={{ __html: html }} />
        </div>
        <hr />
        <Contacts />
      </Layout>
    </Page>
  );
}

export const pageQuery = graphql`
  query ($id: String!, $lang: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
    localize(lang: $lang)
  }
`;
